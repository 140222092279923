import React from "react";
import homePageImg from "../src/images/homePageImage.svg";
import CommonHome_About from "./CommonHome&About";

const HomePage = () => {
  return (
    <div>
      <CommonHome_About
        brandHeading="Grow your business with"
        brandName=" Techorbit."
        brandMotto="We are a team of highly skilled professionals dedicated to delivering comprehensive solutions as a prominent provider in the industry."
        btnValue="Get Started"
        btnRouteTo="/service"
        imgSrc={homePageImg}
      />
    </div>
  );
};
export default HomePage;
