import WebDev from "./images/webDev.svg";
import AndroidDev from "./images/androidDev.svg";
import CloudDev from "./images/cloudComputing1.svg";
import DigitalMarketing from "./images/digitalMarketing.svg";

const ServiceData = [
  {
    imgSrc: WebDev,
    title: "Web Development",
    body: "We offer comprehensive web development services encompassing a wide range of technologies.",
  },
  {
    imgSrc: AndroidDev,
    title: "Native App Development",
    body: "We offer professional Android development services for native app development.",
  },
  {
    imgSrc: CloudDev,
    body: "We provide Cloud Computing Services with Microsoft Azure,Amazon Web Services and Google Cloud.",
    title: "Cloud Services",
  },
  {
    imgSrc: DigitalMarketing,
    body: "We can help you with Digital Marketing, Search Engine Optimization, and Email Marketing.",
    title: "Digital Marketing",
  },
];
export default ServiceData;
