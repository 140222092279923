import React from "react";

const Footer = () => {
  return (
    <React.Fragment>
      <div className="">
        <footer class="fixed-bottom footer mt-auto py-3 bg-light">
          <p className="text-center">© 2023 Techorbit. All Rights Reserved</p>
        </footer>
      </div>
    </React.Fragment>
  );
};
export default Footer;
