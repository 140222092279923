import React from "react";
import AboutUsImg from "../src/images/AboutUsPage.svg";
import CommonHome_About from "./CommonHome&About";

const HomePage = () => {
  const AboutUsData =
    "Innovation and simplicity bring us great satisfaction." +
    "Our objective is to alleviate any technical or financial obstacles that may impede business owners from implementing their own solutions." +
    "We are genuinely excited to provide guidance and support throughout your journey!";

  return (
    <React.Fragment>
      <CommonHome_About
        brandHeading="Welcome to"
        brandName="Techorbit"
        brandMotto={AboutUsData}
        btnValue="Contact Us"
        btnRouteTo="/contact"
        imgSrc={AboutUsImg}
      />
    </React.Fragment>
  );
};
export default HomePage;
